/* eslint-disable camelcase */

import React, { useContext, useEffect } from 'react'
import { Checkbox } from '@mui/material'
import { Text } from 'components'
import Comment from '../../../comps/Comment'
import ConnectedFields from '../../../comps/ConnectedFields'
import FormikMuiInput from '../../../comps/FormikMuiInput'
import FormikMuiSelect from '../../../comps/FormikMuiSelect'
import Info from '../../../comps/Info'
import InlineFields from '../../../comps/InlineFields'
import TextBetween from '../../../comps/TextBetween'
import { fontSizes } from '../../../const/Const'
import { LangContext } from '../../../const/Contexts'
import {
  smokingHistoryKeys,
  smokingCategoryKeys,
  alcoholIntakeKeys,
  noYesKeys,
  caffeineIntakeKeys,
  caffeineCategoryKeys,
  substanceIntakeKeys,
  dayWeekMonthKeys,
  noYesDontKnowKeys,
  menopausalStateKeys,
} from '../../../const/ESQOptions'

export const PPFieldNames = [
  'does_smoke',
  'smoking_year_started',
  'smoking_category',
  'smoking_average_day',
  'alcohol_intake',
  'alcohol_intake_days',
  'alcohol_intake_units',
  'alcohol_sleep_aid',
  'caffeine_intake',
  'caffeine_category',
  'caffeine_category_other_info',
  'caffeine_average_units',
  'caffeine_last_drink',
  'substance_intake',
  'substance_intake_info',
  'substance_intake_days',
  'substance_intake_units',
  'exercise',
  'exercise_count',
  'exercise_count_type',
  'exercise_hours',
  'exercise_hours_type',
  'leisure_recreational_activities',
  'uses_contraception_therapy',
  'is_pregnant',
  'menopausal_state',
  'uses_hormone_replacement',
]

const PersonalProfile = ({ formikProps }) => {
  const { t } = useContext(LangContext)
  const { values, setFieldValue } = formikProps

  const {
    does_smoke,
    smoking_year_started,
    smoking_category,
    smoking_average_day,
    alcohol_intake,
    alcohol_intake_days,
    alcohol_intake_units,
    alcohol_sleep_aid,
    caffeine_intake,
    caffeine_category,
    caffeine_category_other_info,
    caffeine_average_units,
    caffeine_last_drink,
    substance_intake,
    substance_intake_info,
    substance_intake_days,
    substance_intake_units,
    exercise,
    exercise_count,
    exercise_count_type,
    exercise_hours,
    exercise_hours_type,
    __female_disabled__,
    uses_contraception_therapy,
    is_pregnant,
    menopausal_state,
    uses_hormone_replacement,
  } = values

  // Resetting 'Extra' fields when they switch back (ex. 'Other' boxes)
  useEffect(() => {
    if (does_smoke !== 2) {
      if (smoking_year_started !== '') {
        setFieldValue('smoking_year_started', '')
      }
      if (smoking_category.length !== 0) {
        setFieldValue('smoking_category', [])
      }
      if (smoking_average_day !== '') {
        setFieldValue('smoking_average_day', '')
      }
    }
  }, [does_smoke])
  useEffect(() => {
    if (alcohol_intake !== 2) {
      if (alcohol_intake_days !== '') {
        setFieldValue('alcohol_intake_days', '')
      }
      if (alcohol_intake_units !== '') {
        setFieldValue('alcohol_intake_units', '')
      }
      if (alcohol_sleep_aid !== '') {
        setFieldValue('alcohol_sleep_aid', '')
      }
    }
  }, [alcohol_intake])
  useEffect(() => {
    if (caffeine_intake !== 1) {
      if (caffeine_category.length !== 0) {
        setFieldValue('caffeine_category', [])
      }
      if (caffeine_category_other_info !== '') {
        setFieldValue('caffeine_category_other_info', '')
      }
      if (caffeine_average_units !== '') {
        setFieldValue('caffeine_average_units', '')
      }
      if (caffeine_last_drink !== '') {
        setFieldValue('caffeine_last_drink', '')
      }
    }
  }, [caffeine_intake])
  useEffect(() => {
    if (!caffeine_category.includes(3)) {
      if (caffeine_category_other_info !== '') {
        setFieldValue('caffeine_category_other_info', '')
      }
    }
  }, [caffeine_category])
  useEffect(() => {
    if (substance_intake !== 2) {
      if (substance_intake_info !== '') {
        setFieldValue('substance_intake_info', '')
      }
      if (substance_intake_days !== '') {
        setFieldValue('substance_intake_days', '')
      }
      if (substance_intake_units !== '') {
        setFieldValue('substance_intake_units', '')
      }
    }
  }, [substance_intake])
  useEffect(() => {
    if (exercise !== 1) {
      if (exercise_count !== '') {
        setFieldValue('exercise_count', '')
      }
      if (exercise_count_type !== '') {
        setFieldValue('exercise_count_type', '')
      }
      if (exercise_hours !== '') {
        setFieldValue('exercise_hours', '')
      }
      if (exercise_hours_type !== '') {
        setFieldValue('exercise_hours_type', '')
      }
    }
  }, [exercise])
  useEffect(() => {
    if (__female_disabled__ === true) {
      if (uses_contraception_therapy !== '') {
        setFieldValue('uses_contraception_therapy', '')
      }
      if (is_pregnant !== '') {
        setFieldValue('is_pregnant', '')
      }
      if (menopausal_state !== '') {
        setFieldValue('menopausal_state', '')
      }
      if (uses_hormone_replacement !== '') {
        setFieldValue('uses_hormone_replacement', '')
      }
    }
  }, [__female_disabled__])

  return (
    <>
      <Comment center bold text={t('PersonalProfile')} />
      <ConnectedFields>
        <FormikMuiSelect
          label={t('SmokingHistory')}
          name="does_smoke"
          options={smokingHistoryKeys.map(val => t(val))}
        />
        {does_smoke === 2 && (
          <FormikMuiInput label={t('YearStarted')} type="number" name="smoking_year_started" />
        )}
        {does_smoke === 2 && (
          <FormikMuiSelect
            label={t('WhatDoYouSmoke')}
            name="smoking_category"
            options={smokingCategoryKeys.map(val => t(val))}
            multiple
          />
        )}
        {does_smoke === 2 && (
          <Info text={t('UnitConsumptionInformation')}>
            <FormikMuiInput
              label={`${t('AverageQuantity')} (${t('Units/day')})`}
              type="number"
              name="smoking_average_day"
            />
          </Info>
        )}
      </ConnectedFields>
      <ConnectedFields>
        <FormikMuiSelect
          label={t('AlcoholeIntake')}
          name="alcohol_intake"
          options={alcoholIntakeKeys.map(val => t(val))}
        />
        {alcohol_intake === 2 && (
          <FormikMuiInput label={t('Days/week')} type="number" name="alcohol_intake_days" />
        )}
        {alcohol_intake === 2 && (
          <Info text={t('UnitConsumptionInformation')}>
            <FormikMuiInput label={t('Units/week')} type="number" name="alcohol_intake_units" />
          </Info>
        )}
        {alcohol_intake === 2 && (
          <FormikMuiSelect
            label={t('AlcoholeSleep')}
            name="alcohol_sleep_aid"
            options={noYesKeys.map(val => t(val))}
          />
        )}
      </ConnectedFields>
      <ConnectedFields>
        <FormikMuiSelect
          label={t('CaffeinceIntake')}
          name="caffeine_intake"
          // no translations for this so changed to no/yes
          // options={caffeineIntakeKeys.map(val => t(val))}
          // also changed caffeine_intake === 2 below to === 1
          options={noYesKeys.map(val => t(val))}
        />
        {caffeine_intake === 1 && (
          <FormikMuiSelect
            label={t('CaffeinceIntake')}
            name="caffeine_category"
            options={caffeineCategoryKeys.map(val => t(val))}
            multiple
          />
        )}
        {caffeine_intake === 1 && caffeine_category.includes(3) && (
          <FormikMuiInput
            label={`${t('CaffeinceIntake')} - ${t('Other')}`}
            type="text"
            name="caffeine_category_other_info"
          />
        )}
        {caffeine_intake === 1 && (
          <Info text={t('UnitConsumptionInformation')}>
            <FormikMuiInput
              label={t('CaffeinceUnits')}
              type="number"
              name="caffeine_average_units"
            />
          </Info>
        )}
        {caffeine_intake === 1 && (
          <FormikMuiInput
            label={t('WhatTimeOfDayDoYouConsumeYourLastCaffeinatedDrink?')}
            type="time"
            name="caffeine_last_drink"
          />
        )}
      </ConnectedFields>
      <ConnectedFields>
        <Info text={t('DrugUseInformation')}>
          <FormikMuiSelect
            label={t('SubstanceUse')}
            name="substance_intake"
            options={substanceIntakeKeys.map(val => t(val))}
          />
        </Info>
        {substance_intake === 2 && (
          <FormikMuiInput label={t('SubstanceType')} type="text" name="substance_intake_info" />
        )}
        {substance_intake === 2 && (
          <FormikMuiInput label={t('Days/week')} type="number" name="substance_intake_days" />
        )}
        {substance_intake === 2 && (
          <Info text={t('UnitConsumptionInformation')}>
            <FormikMuiInput label={t('Units/week')} type="number" name="substance_intake_units" />
          </Info>
        )}
      </ConnectedFields>
      <ConnectedFields>
        <Info text={t('ExerciseInfo')}>
          <FormikMuiSelect
            label={t('DoYouExercise')}
            name="exercise"
            options={noYesKeys.map(val => t(val))}
          />
        </Info>
        {exercise === 1 && (
          <>
            <InlineFields>
              <FormikMuiInput label="" type="number" name="exercise_count" />
              <TextBetween text={t('TimesPer')} />
              <FormikMuiSelect
                label=""
                name="exercise_count_type"
                options={dayWeekMonthKeys.map(val => t(val))}
              />
            </InlineFields>
            <InlineFields>
              <FormikMuiInput label="" type="number" name="exercise_hours" />
              <TextBetween text={t('HoursPer')} />
              <FormikMuiSelect
                label=""
                name="exercise_hours_type"
                options={dayWeekMonthKeys.map(val => t(val))}
              />
            </InlineFields>
          </>
        )}
      </ConnectedFields>
      <FormikMuiInput label={t('Leisure')} type="text" name="leisure_recreational_activities" />
      <Comment text="" />
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={!__female_disabled__}
          onChange={() => setFieldValue('__female_disabled__', !__female_disabled__)}
        />
        <Comment text={t('FemaleInformation')} />
      </div>
      <FormikMuiSelect
        disabled={__female_disabled__}
        label={t('IAmUsingContraceptionTherapy')}
        name="uses_contraception_therapy"
        options={noYesKeys.map(val => t(val))}
      />
      <FormikMuiSelect
        disabled={__female_disabled__}
        label={t('Pregnant')}
        name="is_pregnant"
        options={noYesDontKnowKeys.map(val => t(val))}
      />
      <Info text={t('MenopauseInformation')}>
        <FormikMuiSelect
          disabled={__female_disabled__}
          label={t('Menopause')}
          name="menopausal_state"
          options={menopausalStateKeys.map(val => t(val))}
        />
      </Info>
      <FormikMuiSelect
        disabled={__female_disabled__}
        label={t('HormoneReplacementTherapy')}
        name="uses_hormone_replacement"
        options={noYesKeys.map(val => t(val))}
      />
    </>
  )
}

export default PersonalProfile
